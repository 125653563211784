import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import imgBanner from '../../common/images/product/product-banner.png';
import imgBannerNky from '../../common/images/product/product-banner-nky.png';
import imgProductNkyPos from '../../common/images/product/product-content-nky-pos.png';
import imgProductNkyApp from '../../common/images/product/product-content-nky-app.png';
import imgProduct1 from '../../common/images/product/product1.png';
import imgProduct2 from '../../common/images/product/product2.png';
import imgProduct3 from '../../common/images/product/product3.png';
import imgProduct4 from '../../common/images/product/product4.png';
import imgRole1 from '../../common/images/product/role1.png';
import imgRole2 from '../../common/images/product/role2.png';
import imgRole3 from '../../common/images/product/role3.png';
import imgRole4 from '../../common/images/product/role4.png';
import imgCore1 from '../../common/images/product/core1.png';
import imgCore2 from '../../common/images/product/core2.png';
import imgCore3 from '../../common/images/product/core3.png';
import Title from '../../component/title/index';
import MyProduct from './index/product';
import Role from './index/role';
import './style.less';

// @withRouter
class Product extends Component {

    render() {
        const layout6 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
        const { search = '' } = this.props.location;

        const renderNkyPos = () => {
            return (
                <div className='product'>
                    <div className='banner'><img src={imgBannerNky} alt='农可以' /></div>
                    <div className='content-nky-title'>四大功能介绍</div>
                    <div className='content-nky'>
                        <img src={imgProductNkyPos} alt='农可以' />
                    </div>
                </div>
            )
        }

        const renderNkyApp = () => {
            return (
                <div className='product'>
                    <div className='banner'><img src={imgBannerNky} alt='农可以' /></div>
                    <div className='content-nky-title'>功能介绍</div>
                    <div className='content-nky'>
                        <img src={imgProductNkyApp} alt='农可以' />
                    </div>
                </div>
            )
        }

        const renderCxm = () => {
            return (
                <div className='product'>
                    <div className='banner'><img src={imgBanner} alt='菜小秘' /></div>
                    <div className="content2">
                        <Title>四大功能介绍</Title>
                        <div className="container">
                            <MyProduct imgAlign='right' img={imgProduct2} title="收银下单" subTitle="快捷操作拒绝遗漏" content="" />
                            <MyProduct imgAlign='left' img={imgProduct1} title="批次管理" subTitle="库存盘点灵活精准" content="" />
                            <MyProduct imgAlign='right' img={imgProduct3} title="赊欠预警 " subTitle="智能提醒降低风险" content="" />
                            <MyProduct imgAlign='left' img={imgProduct4} title="数据经营" subTitle="专业报表高效对账" content="" />
                        </div>
                    </div>
                    <div className="content3">
                        <Title>多终端角色协同</Title>
                        <div className="container">
                            <Row gutter={20} type="flex" justify="space-between" align="middle">
                                <Col {...layout6}>
                                    <Role img={imgRole1} title="销售经理" />
                                </Col>
                                <Col {...layout6}>
                                    <Role img={imgRole2} title="收银" />
                                </Col>
                                <Col {...layout6}>
                                    <Role img={imgRole3} title="财务" />
                                </Col>
                                <Col {...layout6}>
                                    <Role img={imgRole4} title="档主" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="content4">
                        <Title>三大核心优势</Title>
                        <div className="container">
                            <div className="core core1">
                                <div className="img"><img src={imgCore1} alt='' /></div>
                                <div className="text">
                                    <div className="title">技术引领</div>
                                    <div className="sub-content">
                                        <span className="sub-title">【操作简单】</span><span>即学即会，秒速下单提高效率</span><br />
                                        <span className="sub-title">【场景适配】</span><span>功能组合，适配多种场景解决方案</span><br />
                                        <span className="sub-title">【高效管理】</span><span>账单明晰，赊欠风控放心无忧</span><br />
                                        <span className="sub-title">【移动办公】</span><span>方便快捷，多端协作随即切换</span><br />
                                        <span className="sub-title">【安全保障】</span><span>本地部署，随时保障你的数据安全</span><br />
                                    </div>
                                </div>
                            </div>
                            <div className="core core2">
                                <div className="img"><img src={imgCore2} alt='' /></div>
                                <div className="text">
                                    <div className="title">客户为先</div>
                                    <div className="sub-content">
                                        <span className="sub-title">【研发】</span><span>200+研发团队，技术支持，快速迭代</span><br />
                                        <span className="sub-title">【售后】</span><span>300+售后客服，24h跟踪，高效反馈</span><br />
                                        <span className="sub-title">【服务】</span><span>全国覆盖，遍布31个省</span><br />
                                    </div>
                                </div>
                            </div>
                            <div className="core core3">
                                <div className="img"><img src={imgCore3} alt='' /></div>
                                <div className="text">
                                    <div className="title">厚积薄发</div>
                                    <div className="sub-content">
                                        <span className="sub-title">【沉淀】</span><span>30年农批行业积累</span><br />
                                        <span className="sub-title">【覆盖】</span><span>合作商行50000+</span><br />{/**1000+商行覆盖合作 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const renderContent = () => {
            const isNkyPos = search.indexOf('sub=nkyPos') !== -1;
            const isNkyApp = search.indexOf('sub=nkyApp') !== -1;
            if (isNkyPos) {
                return renderNkyPos()
            } else if (isNkyApp) {
                return renderNkyApp()
            } else {
                return renderCxm()
            }
        }

        return renderContent()

    }

}

export default Product;